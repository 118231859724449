import { ref } from "vue";
import { defineStore } from "pinia";
import { YearsToSeconds } from "@ilihub/time";
import type { QueryParameters } from "~/composables/useQueryParameters";

export const useMetaTrackingStore = defineStore(
  "meta",
  () => {
    const fbclid = ref<string>("");

    function $reset() {
      fbclid.value = "";
    }

    function handleQueryParameters(queryParameters: QueryParameters) {
      if (queryParameters.sessionShouldReset()) {
        $reset();
      }

      const metaId = queryParameters.getAndRemove("fbclid");

      if (metaId) {
        fbclid.value = `fb.1.${Date.now()}.${metaId}`;
      }
    }

    return {
      fbclid,
      handleQueryParameters,
      $reset,
    };
  },
  {
    persist: {
      pick: ["fbclid"],
      storage: persistedState.cookiesWithOptions({
        maxAge: YearsToSeconds(1),
        httpOnly: true,
      }),
    },
  },
);
