import { ref } from "vue";
import { defineStore } from "pinia";
import { HoursToSeconds } from "@ilihub/time";
import type { BiTrackingHeader, TrackingMetadata } from "~/types/biTracking";
import { uuidv4 } from "~/utils/uuid";
import { useBMI } from "~/composables";
import appConfig from "~/app.config";
import type { QueryParameters } from "~/composables/useQueryParameters";

export const useBiTrackingStore = defineStore(
  "biTracking",
  () => {
    const { $pinia, $i18n } = useNuxtApp();

    const onboardingStore = useOnboardingStore($pinia);
    const { getAge } = useAge();
    const { getBMI } = useBMI();

    const heyflowId = ref<string>();
    const sessionId = ref<string>();
    const metadata = ref<TrackingMetadata>({});

    const header = computed<BiTrackingHeader>(() => ({
      age: onboardingStore.registrationParams.dateOfBirth
        ? getAge(onboardingStore.registrationParams.dateOfBirth)
        : null,
      platform: "web",
      language: $i18n.locale.value,
      country: onboardingStore.registrationParams.country ?? null,
      sex: onboardingStore.registrationParams.sex ?? null,
      overallGoal: onboardingStore.registrationParams.goal ?? null,
      weightGoal: onboardingStore.registrationParams.weightGoal ?? null,
      weightCurrent: onboardingStore.registrationParams.startWeight ?? null,
      bodyMassIndex:
        onboardingStore.registrationParams.startWeight && onboardingStore.registrationParams.bodyHeight
          ? getBMI(onboardingStore.registrationParams.startWeight, onboardingStore.registrationParams.bodyHeight)
          : null,
      userUUID: onboardingStore.registrationParams.userUuid ?? null,
      properties: JSON.stringify({
        stripe_elements: true,
        mediasource: metadata.value.utm_medium ?? "",
        channel: metadata.value.utm_source ?? "",
        campaignid: metadata.value.utm_campaign ?? "",
        adsetid: metadata.value.utm_term ?? "",
        adid: metadata.value.utm_content ?? "",
        heyflow_id: heyflowId.value ?? "",
        skipped_onboarding: onboardingStore.skippedOnboarding,
      }),
    }));

    function getSessionId(): string {
      if (!sessionId.value) {
        sessionId.value = uuidv4();
      }
      return sessionId.value;
    }

    function $reset() {
      heyflowId.value = undefined;
      sessionId.value = undefined;
      metadata.value = {};
    }

    function handleQueryParameters(queryParameters: QueryParameters) {
      if (queryParameters.sessionShouldReset()) {
        $reset();
      }

      sessionId.value = queryParameters.getAndRemove("session_uuid") ?? sessionId.value;
      heyflowId.value = queryParameters.getAndRemove("flowid") ?? heyflowId.value;

      metadata.value.partner = queryParameters.getAndRemoveAll(["code", "sid", "partner"]) ?? metadata.value.partner;
      metadata.value.utm_campaign = queryParameters.getAndRemove("utm_campaign") ?? metadata.value.utm_campaign;
      metadata.value.utm_content = queryParameters.getAndRemove("utm_content") ?? metadata.value.utm_content;
      metadata.value.utm_medium = queryParameters.getAndRemove("utm_medium") ?? metadata.value.utm_medium;
      metadata.value.utm_source = queryParameters.getAndRemove("utm_source") ?? metadata.value.utm_source;
      metadata.value.utm_term = queryParameters.getAndRemove("utm_term") ?? metadata.value.utm_term;
      metadata.value.skipped_onboarding = onboardingStore.skippedOnboarding;
    }

    return {
      $reset,
      heyflowId,
      metadata,
      sessionId,
      header,
      getSessionId,
      handleQueryParameters,
    };
  },
  {
    persist: {
      pick: ["heyflowId", "sessionId", "metadata"],
      storage: persistedState.cookiesWithOptions({
        maxAge: HoursToSeconds(appConfig.cookieMaxAgeH),
      }),
    },
  },
);
