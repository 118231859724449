import type { PurchaseEvent, AddToCartEvent, ImpressionEvent, ActionEvent } from "~/stores/useTrackingStore";

/**
 * Tracking client that unifies all tracking services and provides a single interface to track events.
 */
export function useTrackingClient() {
  const { $pinia } = useNuxtApp();
  const awinStore = useAwinStore($pinia);

  function trackPurchase(purchaseEvent: PurchaseEvent) {
    const gtmEvent: Record<string, Record<string, string | number>> = {
      ecommerce: {
        transaction_id: purchaseEvent.transactionId,
        value: (purchaseEvent.totalAmount / 100).toFixed(2),
        currency: purchaseEvent.currencyCode.toLocaleUpperCase(),
        item_name: `P${purchaseEvent.period}M`,
        coupon: purchaseEvent.discountCode || "",
        source: awinStore.source,
        email: purchaseEvent.email,
        trial_days: purchaseEvent.trial_days,
      },
    };

    if (awinStore.isBasketFreezeActive()) {
      gtmEvent.ecommerce.basket_freeze = awinStore.getCurrentAwc();
    }

    useGTM().trackEvent("purchase", gtmEvent);
  }

  function trackAddToCartEvent(addToCartEvent: AddToCartEvent) {
    const gtmEvent: Record<string, string | number | Record<string, string | number>[]> = {
      currency: addToCartEvent.currencyCode.toLocaleUpperCase(),
      value: (addToCartEvent.price / 100).toFixed(2),
      items: [
        {
          item_id: addToCartEvent.sku,
          item_name: addToCartEvent.period,
          price: (addToCartEvent.price / 100).toFixed(2),
          quantity: 1,
          coupon: addToCartEvent.discountCode || "",
        },
      ],
    };

    useGTM().trackEvent("add_to_cart", gtmEvent);
  }

  function trackImpression(impressionEvent: ImpressionEvent) {
    void useBiTrackingClient().trackImpression(impressionEvent);
  }

  function trackAction(actionEvent: ActionEvent) {
    void useBiTrackingClient().trackAction(actionEvent);
  }

  return {
    trackPurchase,
    trackAddToCartEvent,
    trackImpression,
    trackAction,
  };
}
