import { z } from "zod";
import { useBackendApiContentDomainConfig } from "~/composables/api/useBackendApiContentDomainConfig";

export const PartnerContentSchema = z.object({
  image_url: z.string().optional(),
  name: z.string(),
  tips: z.array(z.string()).optional().default([]),
  quote: z.string().optional().default(""),
  plural: z.boolean().default(false),
});

export type PartnerContent = z.infer<typeof PartnerContentSchema>;

export function useBackendApiContentDomainClient() {
  const { baseUrl } = useBackendApiContentDomainConfig();
  const { fetch } = useRequest(baseUrl);

  async function fetchPartnerContentByDiscountCode(discountCode: string) {
    const partnerContent = await fetch<PartnerContent>(`/content/v1/partner-content/${discountCode}`);

    return PartnerContentSchema.parse(partnerContent);
  }

  return {
    fetchPartnerContentByDiscountCode,
  };
}
